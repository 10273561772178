import "./wdyr";

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import { Suspense } from "react";
import ReactDOM from "react-dom";
import "./mocks";

import { Provider } from "react-redux";

import { ApiProvider } from "api";

import DateFnsUtils from "@date-io/date-fns";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  jssPreset,
} from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import createTheme from "theme";

import { THEMES, msalConfig } from "./constants";

import {
  BrowserRouter,
  ExcelGenerator,
  ImageLightbox,
  Modal,
  Notification,
} from "components";
import store from "stores";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { useMediaQuery } from "@material-ui/core";
import * as locales from "common/mui";
import { withHooks } from "enhancers";
import { MaterialDesignContent, SnackbarProvider } from "notistack";
import { I18nextProvider } from "react-i18next";
import Routes from "routes/Routes";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import checkVersion from "./common/check-version";
import i18n from "./common/i18next";
(window as any).version = checkVersion;
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point")!,
});

const customThemeProviderEnhancer = withHooks((props: any, hooks: any) => {
  const { useMemo, useTranslation } = hooks;
  const isMobileSize = useMediaQuery("(max-width: 375px)");
  const { i18n } = useTranslation();

  const theme = useMemo(() => {
    let locale = {};
    switch (i18n.language) {
      case "en":
        locale = locales["en"].core;
        break;
      case "th":
        locale = locales["th"].core;
        break;
      default:
        throw new Error("The language you selected could not be found.");
    }

    return isMobileSize
      ? createTheme(THEMES.MOBILE, locale)
      : createTheme(THEMES.DEFAULT, locale);
  }, [isMobileSize, i18n.language]);

  return { ...props, theme };
});

const customMuiThemeProviderEnhancer = withHooks((props: any, hooks: any) => {
  const { useMemo, useTranslation } = hooks;
  const isMobileSize = useMediaQuery("(max-width: 375px)");
  const { i18n } = useTranslation();

  const theme = useMemo(() => {
    let locale = {};
    switch (i18n.language) {
      case "en":
        locale = locales["en"].core;
        break;
      case "th":
        locale = locales["th"].core;
        break;
      default:
        throw new Error("The language you selected could not be found.");
    }
    return isMobileSize
      ? createTheme(THEMES.MOBILE, locale)
      : createTheme(THEMES.DEFAULT, locale);
  }, [isMobileSize, i18n.language]);

  return { ...props, theme };
});

const CustomMuiThemeProvider = customMuiThemeProviderEnhancer(MuiThemeProvider);
const CustomThemeProvider = customThemeProviderEnhancer(ThemeProvider);
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    backgroundColor: AppColor["Text/Primary"],
  },
  "&.notistack-MuiContent-error": {
    backgroundColor: AppColor["Other/Danger"],
  },
}));

export const msalInstance = new PublicClientApplication(msalConfig);

console.log("Backoffice started");

ReactDOM.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <ApiProvider>
        <Suspense fallback={true}>
          <I18nextProvider i18n={i18n}>
            <HelmetProvider>
              <Helmet
                titleTemplate="%s | eBenefits"
                defaultTitle=" eBenefits"
              />
              <StylesProvider jss={jss}>
                <SnackbarProvider
                  maxSnack={3}
                  Components={{
                    success: StyledMaterialDesignContent,
                    error: StyledMaterialDesignContent,
                  }}
                  hideIconVariant
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <CustomMuiThemeProvider>
                      <CustomThemeProvider>
                        <BrowserRouter>
                          <Routes />
                          <Modal storeName="appStore.mainModal" />
                          <Modal storeName="appStore.alertModal" />
                          <Modal storeName="appStore.confirmModal" />
                          <ImageLightbox storeName="appStore.imageLightbox" />
                          <Notification />
                          <ExcelGenerator storeName="appStore.excelGenerator" />
                        </BrowserRouter>
                      </CustomThemeProvider>
                    </CustomMuiThemeProvider>
                  </MuiPickersUtilsProvider>
                </SnackbarProvider>
              </StylesProvider>
            </HelmetProvider>
          </I18nextProvider>
        </Suspense>
      </ApiProvider>
    </MsalProvider>
  </Provider>,
  document.getElementById("root")
);
