import React from "react";
import { compose, withHooks } from "enhancers";
import { Avatar } from "components";
import { AvatarProps } from "@material-ui/core";

const ImageUpload = (props: AvatarProps) => (
  <Avatar
    {...props}
    variant="square"
    style={{ height: 120, width: 120 }}
    src={props.src}
  />
);

const enhancer = compose(
  withHooks((props: any, hooks: any) => {
    const { useEffect } = hooks;
    const { field, form, ...rest } = props;

    const formFile = field.value;

    useEffect(() => {
      if (formFile?.fileName && formFile?.url) {
        const loadFile = async () => {
          const blob = await fetch(formFile?.url).then((r) => r.blob());
          const file = new File([blob], formFile?.fileName, {
            type: blob.type,
          });
          form.setFieldValue(field.name, file);
        };
        loadFile();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formFile]);

    const src = formFile instanceof File ? URL.createObjectURL(formFile) : null;

    return {
      ...rest,
      src,
    };
  })
);

export default enhancer(ImageUpload);
