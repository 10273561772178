import { Redirect } from "react-router-dom";
import { compose, withHooks, withStores, withTranslation } from "enhancers";
import { Path } from "routes/paths";
import { useMemo } from "react";
import { MAIN_MENU } from "routes/menu";
import appStore from "stores/appStore";
import Modal from "./Modal";

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "api" }),
  withHooks((props: any) => {
    const { currentUser, t } = props;
    const getPathAsString = (path: Path) => {
      return path instanceof Path ? path.toString() : path;
    };
    const to = useMemo(() => {
      if (props.withPermissions) {
        let goTo;
        for (const menu of MAIN_MENU) {
          const havePermission = menu.permissions?.every((perm) =>
            currentUser?.role?.permissions.includes(perm)
          );
          if (havePermission) {
            goTo = menu.path;
            break;
          }
        }
        if (goTo) {
          return goTo.toString();
        } else {
          Modal.alert({
            title: t(".modal403Title"),
            children: t(".modal403Children"),
            okButtonLabel: t(".modal403OkButtonLabel"),
            onOk: async ({ close }: any) => {
              appStore.logout();
              close();
            },
            disableBackdropClick: true,
          });
          return getPathAsString(props.to);
        }
      } else {
        return getPathAsString(props.to);
      }
    }, [props.to, props.withPermissions, currentUser, t]);

    return {
      ...props,
      to,
    };
  })
);

export default enhancer(Redirect);
