import {
  compose,
  withHooks,
  withFormik,
  withTranslation,
  withStores,
} from "enhancers";
import { Notification } from "components";
import {
  getErrorMessage,
  gql,
  notifyError,
  paths,
  homePath,
} from "utils/helper";
import { makeStyles } from "@material-ui/core/styles";
import { RoleNewPage } from "./new";
import { useTranslation } from "react-i18next";
import {
  EXPORT_REPORT_PERMISSIONS,
  PERMISSIONS,
} from "constants/enums/permissions";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { difference } from "lodash";

export const API = {
  FETCH_ROLE: gql`
    query FETCH_ROLE($id: String!) {
      role(id: $id) {
        id
        title
        description
        permissions
        createdAt
        updatedAt
      }
    }
  `,
  FETCH_ROLE_OPTIONS: gql`
    query FETCH_ROLE_OPTIONS {
      roleOptions {
        roleOptions {
          label
          value
        }
        permissionOptions
        exportReportPermissionOptions
      }
    }
  `,
  EDIT_ROLE: gql`
    mutation EDIT_ROLE(
      $id: String
      $title: String
      $permissions: [String!]
      $description: String
    ) {
      editRole(
        input: {
          id: $id
          title: $title
          permissions: $permissions
          description: $description
        }
      ) {
        role {
          id
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    enableReinitialize: true,
  }),
  withPreventLeaveDirtyForm(),
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.roles.edit" }),
  withHooks((props: any, hooks: any) => {
    const { setInitialValues, values, currentUser, setValues, t } = props;
    const {
      useQuery,
      useMutation,
      useMemo,
      useHandleSubmit,
      useEffect,
      useParams,
      useCallback,
      usePrevious,
    } = hooks;
    const { id } = useParams();
    const previousPermissions = usePrevious(values?.permissions) || [];
    const { loading, data, error, refetch } = useQuery(API.FETCH_ROLE, {
      variables: { id },
    });
    const { data: roleOptionData } = useQuery(API.FETCH_ROLE_OPTIONS);
    const [editRole] = useMutation(API.EDIT_ROLE);

    const role = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.rolesPath().push();
        return null;
      }
      return data.role;
    }, [loading, data, error]);

    const title = t(".editRole");

    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".home") },
        {
          path: paths.rolesPath(),
          label: t(".indexTitle"),
        },
        { path: null, label: title },
      ];
    }, [title, t]);

    useEffect(() => {
      refetch();
    }, [refetch]);

    useEffect(() => {
      setInitialValues(role);
    }, [setInitialValues, role]);

    useHandleSubmit(
      async (values: any) => {
        try {
          await editRole({
            variables: values,
          });
          paths.rolesPath().push();
          Notification.success("แก้ไขข้อมูลสำเร็จ");
        } catch (e) {}
      },
      [editRole]
    );

    const handleCancel = useCallback(() => {
      paths.rolesPath().push();
    }, []);

    const enabledSubmitButton =
      values?.permissions?.length > 0 && values?.title;

    const roleOptions = useMemo(() => {
      return roleOptionData?.roleOptions.roleOptions || [];
    }, [roleOptionData]);

    const permissionOptions = useMemo(
      () => roleOptionData?.roleOptions.permissionOptions || {},
      [roleOptionData]
    );

    const exportReportPermissionOptions = useMemo(
      () => roleOptionData?.roleOptions.exportReportPermissionOptions || {},
      [roleOptionData]
    );

    const allReportPermissions = useMemo(() => {
      return (
        Object.keys(EXPORT_REPORT_PERMISSIONS).filter(
          (permission) =>
            permission !== EXPORT_REPORT_PERMISSIONS.EXPORT_ALL_REPORT
        ) || []
      );
    }, []);

    const hasEditPermission = useMemo(() => {
      const permission = ["PERMISSION_MANAGEMENT_EDIT"];
      return permission.every((perm) =>
        currentUser?.role?.permissions.includes(perm)
      );
    }, [currentUser]);

    const isSuperAdminRole = useMemo(() => {
      return role?.title?.toLowerCase().replace(/\s/g, "") === "superadmin";
    }, [role]);

    const havePermission = useCallback(
      (permissions: any) => {
        return permissions?.every((perm: any) =>
          values?.permissions?.includes(perm)
        );
      },
      [values?.permissions]
    );

    const previousHavePermission = useCallback(
      (permissions: any) => {
        return permissions?.every((perm: any) =>
          previousPermissions.includes(perm)
        );
      },
      [previousPermissions]
    );

    const haveReportPermission = useCallback(() => {
      return allReportPermissions.every((perm: any) =>
        values?.permissions?.includes(perm)
      );
    }, [allReportPermissions, values?.permissions]);

    const setPermission = useCallback(
      (permissions: any) => {
        let perms = values?.permissions;
        permissions.forEach((permission: any) => {
          if (!havePermission([permission])) perms.push(permission);
        });
        setValues({ ...values, permissions: perms });
      },
      [havePermission, setValues, values]
    );

    const removePermission = useCallback(
      (permissions: any) => {
        const perms = values?.permissions || [];
        const newPerms = difference(perms, permissions);
        setValues({ ...values, permissions: newPerms });
      },
      [setValues, values]
    );

    const disabledList = useMemo(() => {
      const list = [];
      if (havePermission([PERMISSIONS.ADMIN_MANAGEMENT_EDIT])) {
        list.push(PERMISSIONS.ADMIN_MANAGEMENT_READ);
      }
      if (havePermission([PERMISSIONS.PERMISSION_MANAGEMENT_EDIT])) {
        list.push(PERMISSIONS.PERMISSION_MANAGEMENT_READ);
      }
      if (havePermission([PERMISSIONS.REPORT_MANAGEMENT_EDIT])) {
        list.push(PERMISSIONS.REPORT_MANAGEMENT_READ);
      }
      if (havePermission([PERMISSIONS.SETTING_MANAGEMENT_EDIT])) {
        list.push(PERMISSIONS.SETTING_MANAGEMENT_READ);
      }
      return list;
    }, [havePermission]);

    useEffect(() => {
      if (havePermission([PERMISSIONS.ADMIN_MANAGEMENT_EDIT])) {
        setPermission([PERMISSIONS.ADMIN_MANAGEMENT_READ]);
      }
      if (havePermission([PERMISSIONS.PERMISSION_MANAGEMENT_EDIT])) {
        setPermission([PERMISSIONS.PERMISSION_MANAGEMENT_READ]);
      }
      if (havePermission([PERMISSIONS.REPORT_MANAGEMENT_EDIT])) {
        setPermission([PERMISSIONS.REPORT_MANAGEMENT_READ]);
      }
      if (havePermission([PERMISSIONS.SETTING_MANAGEMENT_EDIT])) {
        setPermission([PERMISSIONS.SETTING_MANAGEMENT_READ]);
      }
      const previousHaveExportAllReports = previousHavePermission([
        EXPORT_REPORT_PERMISSIONS.EXPORT_ALL_REPORT,
      ]);
      const haveExportAllReports = havePermission([
        EXPORT_REPORT_PERMISSIONS.EXPORT_ALL_REPORT,
      ]);
      const haveAllReportPermissions = haveReportPermission();
      if (!previousHaveExportAllReports && haveExportAllReports) {
        setPermission(Object.keys(EXPORT_REPORT_PERMISSIONS));
      } else if (
        previousHaveExportAllReports &&
        !haveExportAllReports &&
        haveAllReportPermissions
      ) {
        removePermission(Object.keys(EXPORT_REPORT_PERMISSIONS));
      } else if (
        !previousHaveExportAllReports &&
        !haveExportAllReports &&
        haveAllReportPermissions
      ) {
        setPermission([EXPORT_REPORT_PERMISSIONS.EXPORT_ALL_REPORT]);
      } else if (
        previousHaveExportAllReports &&
        haveExportAllReports &&
        !haveAllReportPermissions
      ) {
        removePermission([EXPORT_REPORT_PERMISSIONS.EXPORT_ALL_REPORT]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.permissions]);

    return {
      enabledSubmitButton,
      breadcrumbs,
      title,
      roleOptions,
      permissionOptions,
      exportReportPermissionOptions,
      handleCancel,
      hasEditPermission,
      isSuperAdminRole,
      disabledList,
    };
  })
);

export default enhancer(RoleNewPage);
