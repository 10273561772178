import { compose, withHooks, withFormik, withTranslation } from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  Button,
  Notification,
  TextField,
} from "components";
import { gql, homePath, paths } from "utils/helper";
import { makeStyles } from "@material-ui/core/styles";
import ErrorMessage from "components/common/ErrorMessage";
import Checkboxes from "components/common/Checkboxes";
import { AppColor } from "theme/app-color";
import {
  EXPORT_REPORT_PERMISSIONS,
  PERMISSIONS,
} from "constants/enums/permissions";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { difference } from "lodash";

export const RoleNewPage = (props: any) => (
  <PageContent
    title={props.title}
    breadcrumbs={props.breadcrumbs}
    pageActions={props.pageActions}
  >
    <Form>
      <Box display="flex" flexDirection="column">
        <Typography variant="h4">{props.t(".detail")}</Typography>
        <ErrorMessage with="100%" name="__error__" mt={6} />
        <Box mt={6}>
          <Box display="flex">
            <Box display="flex" flexDirection="column" width="100%">
              <Field
                component={TextField}
                name="title"
                type="text"
                label={props.t(".roleTitle")}
                required
                fullWidth
                fast={false}
                disabled={!props.hasEditPermission || props.isSuperAdminRole}
              />
              <Box mt={6}>
                <Field
                  component={TextField}
                  name="description"
                  type="text"
                  label={props.t(".description")}
                  fullWidth
                  fast={false}
                  disabled={!props.hasEditPermission || props.isSuperAdminRole}
                />
              </Box>
              <Box mt={10}>
                <Checkboxes
                  label="การเข้าถึง"
                  name="permissions"
                  options={props.permissionOptions}
                  exportReportPermissionOptions={
                    props.exportReportPermissionOptions
                  }
                  hasEditPermission={props.hasEditPermission}
                  isSuperAdminRole={props.isSuperAdminRole}
                  disabledList={props.disabledList}
                />
              </Box>
            </Box>
          </Box>
          {props.hasEditPermission && !props.isSuperAdminRole && (
            <>
              <Button
                width={74}
                mt={4}
                mr={4}
                onClick={props.handleCancel}
                variant="outlined"
              >
                {props.t(".cancel")}
              </Button>
              <Button
                type="submit"
                width={74}
                mt={4}
                disabled={!props.enabledSubmitButton}
                variant="contained"
              >
                {props.t(".save")}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  FETCH_ROLE_OPTIONS: gql`
    query FETCH_ROLE_OPTIONS {
      roleOptions {
        roleOptions {
          label
          value
        }
        permissionOptions
        exportReportPermissionOptions
      }
    }
  `,
  CREATE_ROLE: gql`
    mutation CREATE_ROLE(
      $title: String
      $permissions: [String!]
      $description: String
    ) {
      createRole(
        input: {
          title: $title
          permissions: $permissions
          description: $description
        }
      ) {
        role {
          id
        }
      }
    }
  `,
};

const enhancer = compose(
  withFormik({
    mapPropsToValues: () => ({
      permissions: [],
    }),
  }),
  withPreventLeaveDirtyForm(),
  withTranslation({ prefix: "pages.main.roles.new" }),
  withHooks((props: any, hooks: any) => {
    const {
      useMutation,
      useMemo,
      useHandleSubmit,
      useQuery,
      useEffect,
      useCallback,
      usePrevious,
    } = hooks;
    const { values, setValues, t } = props;
    const previousPermissions = usePrevious(values?.permissions) || [];
    const { data } = useQuery(API.FETCH_ROLE_OPTIONS);
    const [createRole] = useMutation(API.CREATE_ROLE);

    const title = t(".addRole");

    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".home") },
        { path: paths.rolesPath(), label: t(".role") },
        { path: null, label: title },
      ];
    }, [title, t]);

    useHandleSubmit(
      async (values: any) => {
        await createRole({ variables: { ...values } });
        paths.rolesPath().push();
        Notification.success("บันทึกสำเร็จ");
      },
      [createRole]
    );

    const roleOptions = useMemo(() => {
      return data?.roleOptions.roleOptions || [];
    }, [data]);

    const permissionOptions = useMemo(
      () => data?.roleOptions.permissionOptions || {},
      [data]
    );

    const exportReportPermissionOptions = useMemo(
      () => data?.roleOptions.exportReportPermissionOptions || {},
      [data]
    );

    const allReportPermissions = useMemo(() => {
      return (
        Object.keys(EXPORT_REPORT_PERMISSIONS).filter(
          (permission) =>
            permission !== EXPORT_REPORT_PERMISSIONS.EXPORT_ALL_REPORT
        ) || []
      );
    }, []);

    const enabledSubmitButton =
      values?.permissions?.length > 0 && values?.title;

    const handleCancel = useCallback(() => {
      paths.rolesPath().push();
    }, []);

    const havePermission = useCallback(
      (permissions: any) => {
        return permissions?.every((perm: any) =>
          values?.permissions?.includes(perm)
        );
      },
      [values?.permissions]
    );

    const previousHavePermission = useCallback(
      (permissions: any) => {
        return permissions?.every((perm: any) =>
          previousPermissions.includes(perm)
        );
      },
      [previousPermissions]
    );

    const haveReportPermission = useCallback(() => {
      return allReportPermissions.every((perm: any) =>
        values?.permissions?.includes(perm)
      );
    }, [allReportPermissions, values?.permissions]);

    const setPermission = useCallback(
      (permissions: any) => {
        let perms = values?.permissions;
        permissions.forEach((permission: any) => {
          if (!havePermission([permission])) perms.push(permission);
        });
        setValues({ ...values, permissions: perms });
      },
      [havePermission, setValues, values]
    );

    const removePermission = useCallback(
      (permissions: any) => {
        const perms = values?.permissions || [];
        const newPerms = difference(perms, permissions);
        setValues({ ...values, permissions: newPerms });
      },
      [setValues, values]
    );

    const disabledList = useMemo(() => {
      const list = [];
      if (havePermission([PERMISSIONS.ADMIN_MANAGEMENT_EDIT])) {
        list.push(PERMISSIONS.ADMIN_MANAGEMENT_READ);
      }
      if (havePermission([PERMISSIONS.PERMISSION_MANAGEMENT_EDIT])) {
        list.push(PERMISSIONS.PERMISSION_MANAGEMENT_READ);
      }
      if (havePermission([PERMISSIONS.REPORT_MANAGEMENT_EDIT])) {
        list.push(PERMISSIONS.REPORT_MANAGEMENT_READ);
      }
      if (havePermission([PERMISSIONS.SETTING_MANAGEMENT_EDIT])) {
        list.push(PERMISSIONS.SETTING_MANAGEMENT_READ);
      }
      return list;
    }, [havePermission]);

    useEffect(() => {
      if (havePermission([PERMISSIONS.ADMIN_MANAGEMENT_EDIT])) {
        setPermission([PERMISSIONS.ADMIN_MANAGEMENT_READ]);
      }
      if (havePermission([PERMISSIONS.PERMISSION_MANAGEMENT_EDIT])) {
        setPermission([PERMISSIONS.PERMISSION_MANAGEMENT_READ]);
      }
      if (havePermission([PERMISSIONS.REPORT_MANAGEMENT_EDIT])) {
        setPermission([PERMISSIONS.REPORT_MANAGEMENT_READ]);
      }
      if (havePermission([PERMISSIONS.SETTING_MANAGEMENT_EDIT])) {
        setPermission([PERMISSIONS.SETTING_MANAGEMENT_READ]);
      }
      const previousHaveExportAllReports = previousHavePermission([
        EXPORT_REPORT_PERMISSIONS.EXPORT_ALL_REPORT,
      ]);
      const haveExportAllReports = havePermission([
        EXPORT_REPORT_PERMISSIONS.EXPORT_ALL_REPORT,
      ]);
      const haveAllReportPermissions = haveReportPermission();
      if (!previousHaveExportAllReports && haveExportAllReports) {
        setPermission(Object.keys(EXPORT_REPORT_PERMISSIONS));
      } else if (
        previousHaveExportAllReports &&
        !haveExportAllReports &&
        haveAllReportPermissions
      ) {
        removePermission(Object.keys(EXPORT_REPORT_PERMISSIONS));
      } else if (
        !previousHaveExportAllReports &&
        !haveExportAllReports &&
        haveAllReportPermissions
      ) {
        setPermission([EXPORT_REPORT_PERMISSIONS.EXPORT_ALL_REPORT]);
      } else if (
        previousHaveExportAllReports &&
        haveExportAllReports &&
        !haveAllReportPermissions
      ) {
        removePermission([EXPORT_REPORT_PERMISSIONS.EXPORT_ALL_REPORT]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.permissions]);

    return {
      title,
      breadcrumbs,
      roleOptions,
      permissionOptions,
      exportReportPermissionOptions,
      enabledSubmitButton,
      handleCancel,
      hasEditPermission: true,
      disabledList,
    };
  })
);

export default enhancer(RoleNewPage);
