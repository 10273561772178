import { ReactComponent as FilterIcon } from "assets/icon/filter_outline.svg";
import styled from "styled-components";
import {
  Box,
  Button,
  DatePicker,
  Field,
  Form,
  Grid,
  Table,
  TextField,
  Typography,
} from "components";
import Select from "components/common/Select";
import {
  compose,
  withAuthorize,
  withFormik,
  withHooks,
  withTranslation,
} from "enhancers";
import { PageContent } from "layouts";
import { BreadcrumbsProps } from "layouts/PageContent";
import { AppColor } from "theme/app-color";
import { formatDate, gql, homePath, paths } from "utils/helper";
import dayjs from "dayjs";
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status";
import { env } from "configs";
import Iframe from "react-iframe";
const FilterCountNotify = styled(Box)`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffa500;
  display: flex;
`;
const RequestIndexPage = (props: any) => (
  <PageContent
    title={props.t(".title")}
    breadcrumbs={props.breadcrumbs}
    pageActions={[]}
    filter={props.filterSection}
  >
    <Box width="100%" mb={-6}>
      <Typography variant="h4" mb={4}>
        {props.t(".title")}
      </Typography>
      <Table
        columns={props.columns}
        rows={props.tableData}
        loading={props.loading}
        autoHeight
        onRowClickTo={paths.requestDetailPath}
      />
    </Box>
  </PageContent>
);

export const API = {
  GET_DASHBOARD_CLAIM_LIST: gql`
    query GET_DASHBOARD_CLAIM_LIST($filters: JSON) {
      dashboardClaimList(input: { filters: $filters }) {
        id
        referenceId
        info
        employee
        status
        createdAt
      }
    }
  `,
  FETCH_OPTIONS: gql`
    query FETCH_OPTIONS {
      getDepartmentOption {
        label
        value
      }
    }
  `,
  GET_MASTER_DATA: gql`
    query GET_MASTER_DATA {
      masterData
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withFormik({
    mapPropsToValues: () => ({
      referenceId: "",
      requesterName: "",
      department: "all",
      claimType: "all",
      claimName: "all",
      disease: "all",
      status: "ALL",
      receiptNo: "",
      startDate: undefined,
      endDate: undefined,
      startRecieptDate: undefined,
      endRecieptDate: undefined,
    }),
  }),
  withTranslation({
    prefix: "pages.main.request.index",
  }),
  withHooks((props: any, hooks: any) => {
    const { t, setValues, values } = props;
    const { useMemo, useState, useCallback, useQuery, useHandleSubmit } = hooks;
    const [filterCount, setFilterCount] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const { loading, data, error, refetch } = useQuery(
      API.GET_DASHBOARD_CLAIM_LIST,
      {
        variables: {
          filters: {
            department: "all",
            claimType: "all",
            claimName: "all",
            disease: "all",
            status: "ALL",
          },
        },
        fetchPolicy: "network-only",
      }
    );
    const { data: masterData } = useQuery(API.GET_MASTER_DATA, {
      fetchPolicy: "network-only",
    });

    const diseaseOption = useMemo(() => {
      const { dental, diseases } = masterData?.masterData || {};
      return [{ label: "ทั้งหมด", value: "all" }].concat(dental, diseases);
    }, [masterData]);

    const { data: departmentOptionsData } = useQuery(API.FETCH_OPTIONS, {
      fetchPolicy: "network-only",
    });

    const departmentOption = useMemo(() => {
      return [{ label: "ทั้งหมด", value: "all" }].concat(
        departmentOptionsData?.getDepartmentOption
      );
    }, [departmentOptionsData]);

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".title"), path: null },
      ],
      [t]
    );

    const columns = useMemo(
      () => [
        {
          width: 200,
          field: "referenceId",
          headerName: t(".refId") || "",
        },
        {
          width: 300,
          field: "requesterName",
          headerName: t(".claimRequester") || "",
        },
        {
          width: 150,
          field: "department",
          headerName: t(".department") || "",
          type: "string",
        },
        {
          width: 150,
          field: "claimType",
          headerName: t(".claimType") || "",
          type: "string",
        },
        {
          width: 150,
          field: "claimName",
          headerName: t(".claimName") || "",
          type: "string",
        },
        {
          width: 150,
          field: "createdAt",
          headerName: t(".createdAt") || "",
          type: "string",
          renderCell: (createdAt: any) => {
            return dayjs(createdAt.value)
              .locale("th")
              .add(543, "year")
              .format("DD/MM/YYYY");
          },
        },
        {
          width: 150,
          field: "status",
          headerName: t(".status") || "",
          type: "string",
        },
        {
          width: 150,
          field: "disease",
          headerName: t(".disease") || "",
          type: "string",
        },
        {
          width: 150,
          field: "receiptNo",
          headerName: t(".refNo") || "",
          type: "string",
        },
        {
          width: 150,
          field: "receiptDate",
          headerName: t(".transferDate") || "",
          type: "string",
          renderCell: (endDate: any) => {
            return endDate.value
              ? dayjs(endDate.value)
                  .locale("th")
                  .add(543, "year")
                  .format("DD/MM/YYYY")
              : "";
          },
        },
      ],
      [t]
    );

    useHandleSubmit(
      async (values: any) => {
        const countAllFields = (obj: any) => {
          return Object.values(obj).filter(
            (value) => value === "ALL" || value === "all"
          ).length;
        };

        const cntFilter = countAllFields(values);

        if (cntFilter > 0) {
          setFilterCount(
            Math.max(
              Object.values(values).filter(Boolean).length - cntFilter,
              0
            )
          );
        } else {
          setFilterCount(Object.values(values).filter(Boolean).length);
        }

        const variables = {
          filters: {
            referenceId: values.referenceId,
            requesterName: values.requesterName,
            department: values.department,
            claimType: values.claimType,
            claimName: values.claimName,
            disease: values.disease,
            status: values.status,
            receiptNo: values.receiptNo,
            startDate: values.startDate,
            endDate: values.endDate,
            startRecieptDate: values.startRecieptDate,
            endRecieptDate: values.endRecieptDate,
          },
        };
        await refetch(variables);
      },
      [refetch]
    );

    const tableData = useMemo(() => {
      if (loading || error) {
        return [];
      }

      return data?.dashboardClaimList.map((req: any) => {
        const { employee, info, status } = req;

        return {
          ...req,
          requesterName: `[${employee.employeeCode}] ${employee.firstName} ${employee.lastName}`,
          department: employee.department,
          claimType: info.type,
          claimName: info.title,
          status: mapStatus(status),
          receiptDate: info.values.inputs["receipt_date"]
            ? mapStringDate(info.values.inputs["receipt_date"])
            : "",
          disease: info.values.inputs["disease"] || "",
          receiptNo: info.values.inputs["receipt_no"] || "",
        };
      });
    }, [loading, data, error]);

    const claimStatusOptions = useMemo(() => {
      return [
        {
          label: "ทั้งหมด",
          value: "ALL",
        },
        {
          label: "รออนุมัติ",
          value: EnumClaimRequestStatus.WAITING,
        },
        {
          label: "อนุมัติ",
          value: EnumClaimRequestStatus.APPROVED,
        },
        {
          label: "ไม่อนุมัติ",
          value: EnumClaimRequestStatus.REJECTED,
        },
        {
          label: "ยกเลิก",
          value: EnumClaimRequestStatus.CANCELED,
        },
      ];
    }, []);

    const claimTypeOptions = useMemo(() => {
      return [
        {
          label: "ทั้งหมด",
          value: "all",
        },
        {
          label: "ค่ารักษาพยาบาล",
          value: "ค่ารักษาพยาบาล",
        },
        {
          label: "ดูแลบุตร",
          value: "ดูแลบุตร",
        },
        {
          label: "กรณีเสียชีวิต",
          value: "กรณีเสียชีวิต",
        },
      ];
    }, []);

    const claimNameOptions = useMemo(() => {
      return [
        {
          label: "ทั้งหมด",
          value: "all",
        },
        {
          label: "ค่ารักษาพยาบาล (ผู้ป่วยนอก - OPD)",
          value: "opd_medical",
        },
        {
          label: "ค่ารักษาพยาบาล (ผู้ป่วยใน - IPD)",
          value: "ipd_medical",
        },
        {
          label: "ทันตกรรม",
          value: "dental_fee",
        },
        {
          label: "ค่าช่วยเหลือการศึกษาบุตร",
          value: "child_education",
        },
        {
          label: "ค่าช่วยเหลือบุตร",
          value: "child_support",
        },
        {
          label: "ค่าช่วยเหลือจัดการงานศพ (3 เท่า)",
          value: "pass_away_support",
        },
        {
          label: "ค่าเจ้าภาพงานศพ",
          value: "pass_away_sponsor",
        },
        {
          label: "ค่าอุปกรณ์เคารพศพ",
          value: "pass_away_wreath",
        },
      ];
    }, []);

    const handleShowFilter = useCallback(() => {
      setShowFilter(!showFilter);
    }, [showFilter]);

    const clearFilter = useCallback(async () => {
      setValues({
        referenceId: "",
        requesterName: "",
        department: "all",
        claimType: "all",
        claimName: "all",
        disease: "all",
        status: "ALL",
        receiptNo: "",
        startDate: undefined,
        endDate: undefined,
        startRecieptDate: undefined,
        endRecieptDate: undefined,
      });
      const variables = {
        filters: {
          referenceId: "",
          requesterName: "",
          department: "all",
          claimType: "all",
          claimName: "all",
          disease: "all",
          status: "ALL",
          receiptNo: "",
          startDate: null,
          endDate: null,
          startRecieptDate: null,
          endRecieptDate: null,
        },
      };
      setFilterCount(0);
      await refetch(variables);
    }, [setValues, values]);

    const filterSection = useMemo(
      () => (
        <Box>
          <Form>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <FilterIcon />
                <Typography variant="h6" mx={2}>
                  {t(".filterTitle")}
                </Typography>
                {filterCount > 0 && (
                  <FilterCountNotify>
                    <Typography variant="body1">{filterCount}</Typography>
                  </FilterCountNotify>
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                onClick={handleShowFilter}
              >
                <Typography
                  variant="body1"
                  style={{
                    textDecoration: "underline",
                    color: AppColor["Primary/Primary Text"],
                    cursor: "pointer",
                  }}
                >
                  {showFilter ? t(".hide") : t(".show")}
                </Typography>
              </Box>
            </Box>
            {showFilter && (
              <Box display="flex" flexDirection="column">
                <Grid mt={6}>
                  <Box>
                    <Grid container spacing={7} mb={4}>
                      <Grid item xs={3}>
                        <Field
                          component={TextField}
                          name="referenceId"
                          type="text"
                          label="เลขอ้างอิง"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          component={Select}
                          name="department"
                          type="text"
                          label="ฝ่ายสังกัด"
                          options={departmentOption}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={TextField}
                          name="requesterName"
                          type="text"
                          label="ผู้ขอเบิก"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={Select}
                          name="claimType"
                          type="text"
                          label="ประเภทสวัสดิการ"
                          options={claimTypeOptions}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={Select}
                          name="claimName"
                          type="text"
                          label="ชื่อสวัสดิการ"
                          options={claimNameOptions}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={Select}
                          name="disease"
                          type="text"
                          label="โรค"
                          options={diseaseOption}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          component={Select}
                          name="status"
                          type="text"
                          label="สถานะ"
                          options={claimStatusOptions}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          component={TextField}
                          name="receiptNo"
                          type="text"
                          label="เลขที่ใบเสร็จ"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          fast={false}
                          component={DatePicker}
                          name="startRecieptDate"
                          label="วันที่ตามใบเสร็จตั้งแต่"
                          fullWidth
                          maxDate={values.endRecieptDate}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          fast={false}
                          component={DatePicker}
                          name="endRecieptDate"
                          label="จนถึงวันที่"
                          fullWidth
                          minDate={values.startRecieptDate}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          fast={false}
                          component={DatePicker}
                          name="startDate"
                          label="วันที่สร้างรายการตั้งแต่"
                          fullWidth
                          maxDate={values.endDate}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Field
                          fast={false}
                          component={DatePicker}
                          name="endDate"
                          label="จนถึงวันที่"
                          fullWidth
                          minDate={values.startDate}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Box display="flex" flexDirection="row-reverse" mt={4}>
                  <Button width={74} type="submit">
                    {t(".filter")}
                  </Button>
                  <Button
                    width={74}
                    mr={6}
                    onClick={clearFilter}
                    variant="outlined"
                  >
                    {t(".reset")}
                  </Button>
                </Box>
              </Box>
            )}
          </Form>
        </Box>
      ),
      [
        t,
        filterCount,
        handleShowFilter,
        showFilter,
        values.endDate,
        values.startDate,
        values.startRecieptDate,
        values.endRecieptDate,
        diseaseOption,
        claimNameOptions,
        claimStatusOptions,
        claimTypeOptions,
        departmentOption,
      ]
    );
    return {
      breadcrumbs,
      filterSection,
      tableData,
      columns,
      loading,
    };
  })
);

const mapStatus = (status: any) => {
  switch (status) {
    case EnumClaimRequestStatus.WAITING:
      return "รออนุมัติ";
    case EnumClaimRequestStatus.APPROVED:
      return "อนุมัติ";
    case EnumClaimRequestStatus.REJECTED:
      return "ไม่อนุมัติ";
    case EnumClaimRequestStatus.CANCELED:
      return "ยกเลิก";
    default:
      return "บันทึกร่าง";
  }
};

const mapStringDate = (dateString: any) => {
  const [day, month, year] = dateString.split("/").map(Number);
  const date = new Date(year, month - 1, day);
  return date;
};

export default enhancer(RequestIndexPage);
