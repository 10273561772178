import { Box, Button, Field, Grid, TextField, Typography } from "components";
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import PageContent, { BreadcrumbsProps } from "layouts/PageContent";
import { isEmpty } from "lodash";
import paths from "routes/paths";
import { getErrorMessage, gql, homePath, notifyError } from "utils/helper";

const BudgetEditPage = (props: any) => (
  <PageContent title={props.t(".rootPath")} breadcrumbs={props.breadcrumbs}>
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <Typography variant="h4">{props?.t(".title")}</Typography>
        </Box>
      </Box>
      <Grid container spacing={6} mt={6}>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="code"
            type="text"
            label={props.t(".code")}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="fullName"
            type="text"
            label={props.t(".fullName")}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="year"
            type="text"
            label={props.t(".year")}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <br />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="companyBudget"
            type="text"
            label={props.t(".companyBudget")}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="budget"
            type="text"
            label={props.t(".budget")}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="companyDentalBudget"
            type="text"
            label={props.t(".companyDentalBudget")}
            fullWidth
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            name="dentalBudget"
            type="text"
            label={props.t(".dentalBudget")}
            fullWidth
            disabled={true}
          />
        </Grid>
      </Grid>
      <Box display="flex" mt={10}>
        <Button width={74} p={0} mr={2} onClick={props.onBack}>
          {props.t(".cancel")}
        </Button>
      </Box>
    </Box>
  </PageContent>
);

export const API = {
  FETECH_EMPLOYEE_YEARLY_BUDGET: gql`
    query FETECH_EMPLOYEE_YEARLY_BUDGET($id: String!) {
      getEmployeeYearlyBudget(id: $id) {
        id
        employeeCode
        firstName
        lastName
        year
        budget
        dentalBudget
        companyBudget
        companyDentalBudget
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({ currentUser: stores.appStore.currentUser })),
  withFormik({}),
  withTranslation({ prefix: "pages.main.budget.edit" }),
  withHooks((props: any, hooks: any) => {
    const { t, setInitialValues } = props;
    const { useQuery, useMemo, useEffect, useParams, useCallback } = hooks;
    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(
      API.FETECH_EMPLOYEE_YEARLY_BUDGET,
      {
        variables: { id },
      }
    );

    useEffect(() => {
      refetch();
    }, [refetch]);

    const getEmployeeYearlyBudget = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.budgetPath().push();
        return null;
      }
      return data.getEmployeeYearlyBudget;
    }, [loading, data, error]);

    useEffect(() => {
      if (getEmployeeYearlyBudget) {
        const {
          employeeCode,
          firstName,
          lastName,
          companyBudget,
          budget,
          companyDentalBudget,
          dentalBudget,
        } = getEmployeeYearlyBudget;
        setInitialValues({
          ...getEmployeeYearlyBudget,
          code: employeeCode,
          fullName:
            (isEmpty(firstName) || firstName == null) &&
            (isEmpty(lastName) || lastName == null)
              ? null
              : `${firstName || ""} ${lastName || ""}`,
          companyBudget: companyBudget.toLocaleString(),
          budget: budget.toLocaleString(),
          companyDentalBudget: companyDentalBudget.toLocaleString(),
          dentalBudget: dentalBudget.toLocaleString(),
        });
      }
    }, [setInitialValues, getEmployeeYearlyBudget]);

    const onBack = useCallback(() => {
      paths.budgetPath().push();
    }, []);

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { path: paths.budgetPath(), label: t(".rootPath") },
        { label: getEmployeeYearlyBudget?.employeeCode, path: null },
      ],
      [getEmployeeYearlyBudget?.employeeCode, t]
    );

    return {
      code: getEmployeeYearlyBudget?.employeeCode,
      onBack,
      breadcrumbs,
      t,
    };
  })
);

export default enhancer(BudgetEditPage);
