import {
  compose,
  withHooks,
  withFormik,
  withStores,
  withTranslation,
} from "enhancers";
import { PageContent } from "layouts";
import {
  Box,
  Typography,
  Form,
  Field,
  TextField,
  Button,
  Grid,
  DatePicker,
} from "components";
import {
  getErrorMessage,
  gql,
  homePath,
  notifyError,
  paths,
} from "utils/helper";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { BreadcrumbsProps } from "layouts/PageContent";

const EmployeeEditPage = (props: any) => (
  <PageContent title={props?.t(".rootPath")} breadcrumbs={props.breadcrumbs}>
    <Form>
      <Box display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Typography variant="h4">{props?.t(".title")}</Typography>
          </Box>
        </Box>
        <Grid container spacing={6} mt={6}>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="code"
              type="text"
              label={props.t(".code")}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="fullName"
              type="text"
              label={props.t(".fullName")}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="phoneNumber"
              type="phoneNumber"
              label={props.t(".phoneNumber")}
              fullWidth
              disabled={true}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="email"
              type="text"
              label={props.t(".email")}
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              name="department"
              type="text"
              label={props.t(".department")}
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={DatePicker}
              name="employmentStartDate"
              label={props.t(".employmentStartDate")}
              disabled={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={DatePicker}
              name="employmentFinishDate"
              label={props.t(".employmentFinishDate")}
              disabled={true}
              fullWidth
            />
          </Grid>
        </Grid>
        <Box display="flex" mt={10}>
          <Button width={74} p={0} mr={2} onClick={props.onBack}>
            {props.t(".cancel")}
          </Button>
        </Box>
      </Box>
    </Form>
  </PageContent>
);

export const API = {
  FETCH_EMPLOYEE: gql`
    query FETCH_EMPLOYEE($id: String!) {
      getEmployee(id: $id) {
        id
        employeeCode
        firstName
        lastName
        phoneNumber
        role
        title
        userName
        birthDate
        gender
        maritalStatus
        nationality
        officialMailId
        employmentStartDate
        employmentFinishDate
        department
        probationStatus
        probationPeriod
        costCenterName
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withFormik({}),
  withTranslation({ prefix: "pages.main.employee.edit" }),
  withPreventLeaveDirtyForm(),
  withHooks((props: any, hooks: any) => {
    const { t, setInitialValues } = props;
    const { useQuery, useMemo, useEffect, useParams } = hooks;
    const { id } = useParams();

    const { loading, data, error, refetch } = useQuery(API.FETCH_EMPLOYEE, {
      variables: { id },
    });

    useEffect(() => {
      refetch();
    }, [refetch]);

    const employee = useMemo(() => {
      if (loading) {
        return null;
      }
      if (error) {
        const message = getErrorMessage(error);
        notifyError(message);
        paths.employeePath().push();
        return null;
      }
      return data.getEmployee;
    }, [loading, data, error]);

    useEffect(() => {
      if (employee) {
        const { firstName, lastName, officialMailId, employeeCode } = employee;

        setInitialValues({
          ...employee,
          code: employeeCode,
          email: officialMailId,
          fullName:
            (isEmpty(firstName) || firstName == null) &&
            (isEmpty(lastName) || lastName == null)
              ? null
              : `${firstName || ""} ${lastName || ""}`,
        });
      }
    }, [setInitialValues, employee]);

    const onBack = useCallback(() => {
      paths.employeePath().push();
    }, []);

    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".rootPath"), path: paths.employeePath() },
        { label: employee?.employeeCode, path: null },
      ],
      [employee?.employeeCode, t]
    );

    return {
      onBack,
      breadcrumbs,
    };
  })
);

export default enhancer(EmployeeEditPage);
