/* eslint-disable i18next/no-literal-string */
import {
  compose,
  withFormik,
  withHooks,
  withStores,
  withTranslation,
} from "enhancers";
import { Yup, gql, homePath } from "utils/helper";

import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { setRefreshToken, setToken } from "api";
import {
  Box,
  Button,
  Field,
  Form,
  Notification,
  TextField,
  Typography,
} from "components";
import { TFunction } from "i18next";
import { useEffect } from "react";
import appStore from "stores/appStore";
import styled from "styled-components";
import { AppColor } from "theme/app-color";
import { VerifyOtp } from "./VerifyOtp";
import { ReactComponent as BamLogo } from "assets/image/bam_logo.svg";
import paths from "../../../routes/paths";

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

interface LoginProps {
  currentEmail?: string;
  handleClickGoBack: () => void;
  refCode?: string;
  otpRetryTimeInSec?: number;
  otpExpireInSec?: number;
  token: string;
  isLoginDisabled: boolean;
  t: TFunction;
  handleClickLoginWithAzureAd: () => void;
  handleLogoutAzureAd: () => void;
  isShowLogout: boolean;
  handleClickResetPassword: () => void;
  isResetPassword: boolean;
}

const LoginPageComponent = (props: LoginProps) => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    minWidth={357}
    width="100%"
    height={900}
    bgcolor={AppColor["Background/Light Grey"]}
  >
    <Box width={520} bgcolor={AppColor["White / White"]} borderRadius="8px">
      <Container>
        <BamLogo />
        <Box
          style={{ marginBottom: 24, marginTop: 16 }}
          display="flex"
          justifyContent="center"
        >
          <Typography variant="h4">{props.t(".title3")}</Typography>
        </Box>
        <Form style={{ width: "100%" }}>
          <Field
            fast
            component={TextField}
            name="email"
            label={props.t(".emailField")}
            fullWidth
            required
          />
          <div style={{ marginTop: 24 }}>
            <Field
              fast
              component={TextField}
              name="password"
              type="password"
              label={props.t(".passwordField")}
              fullWidth
              required
            />
          </div>
          <Box display="flex" justifyContent="flex-end" mt="16px">
            <Typography
              variant="Helper/12"
              color={AppColor["Primary/Primary"]}
              onClick={props.handleClickResetPassword}
              style={{ cursor: "pointer" }}
            >
              {props.t(".ResetPassword")}
            </Typography>
          </Box>
          <div style={{ marginTop: 24, width: "100%" }}>
            <Button disabled={props.isLoginDisabled} type="submit" width="100%">
              {props.t(".login")}
            </Button>
          </div>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "16px",
              width: "100%",
            }}
          >
            <Typography variant="Helper/12">{props.t(".or")}</Typography>
            {props.isShowLogout ? (
              <Button
                onClick={props.handleLogoutAzureAd}
                width="100%"
                mt="16px"
              >
                {props.t(".logoutAzureAd")}
              </Button>
            ) : (
              <Button
                onClick={props.handleClickLoginWithAzureAd}
                width="100%"
                mt="16px"
                variant="outlined"
              >
                {props.t(".signInWithAzureAd")}
              </Button>
            )}
          </Box>
        </Form>
        <Typography
          variant="caption"
          color="Text/Placeholder"
          style={{
            fontSize: "10px",
            width: "100%",
            maxWidth: 440,
            marginTop: 24,
          }}
        >
          *ระบบสารสนเทศนี้เป็นทรัพย์สินของ บสก.
          การใช้งานระบบจะต้องได้รับการอนุมัติก่อนการใช้งานเท่านั้น
          ผู้ที่ไม่ได้ผ่านการอนุมัติตามขั้นตอนของบริษัทจะถือเป็นผู้บุกรุก
          หากมีการตรวจสอบ จะถือเป็นความผิดที่จะต้องมีการลงโทษทางวินัย
          หรือดำเนินการทางกฎหมายตามแต่กรณี บสก.
          สงวนสิทธิ์ในการตรวจสอบและติดตามพฤติกรรมการใช้งานในระหว่างที่ผู้ใช้งานระบบนี้ได้โดยไม่ถือว่าเป็นการละเมิดความเป็นส่วนตัว
        </Typography>
      </Container>
    </Box>
  </Box>
);

const API = {
  SIGN_IN_WITH_AZURE_AD: gql`
    mutation SIGN_IN_WITH_AZURE_AD($email: String!) {
      signInWithAzureAd(email: $email) {
        currentUser {
          id
          email
          authenticationToken
          role {
            permissions
          }
        }
        accessToken
        refreshToken
      }
    }
  `,
  BACKOFFICE_USER_SIGN_IN: gql`
    mutation BACKOFFICE_USER_SIGN_IN($email: String!, $password: String!) {
      backofficeSignIn(input: { email: $email, password: $password }) {
        currentUser {
          id
          email
          authenticationToken
          role {
            permissions
          }
        }
        accessToken
        refreshToken
      }
    }
  `,
};

const enhancer = compose(
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withFormik({
    mapPropsToValues: () => ({
      email: "",
      password: "",
    }),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .transform((originalValue) => originalValue.trim())
        .email(".invalidEmailSyntax"),
      password: Yup.string(),
    }),
  }),
  withTranslation({ prefix: "pages.main.authentication.LoginPage" }),
  withHooks((props: any, hooks: any) => {
    const {
      useState,
      useHandleSubmit,
      useCallback,
      useMutation,
      useMemo,
    } = hooks;
    const { values, currentUser } = props;
    const { instance, accounts } = useMsal();

    const [backofficeSignIn] = useMutation(API.BACKOFFICE_USER_SIGN_IN, {
      onCompleted: (data: any) => {
        const {
          accessToken,
          refreshToken,
          currentUser,
        } = data.backofficeSignIn;
        appStore.setCurrentUser(data.backofficeSignIn);
        setToken(accessToken);
        setRefreshToken(refreshToken);
        window.location.href = homePath(currentUser.role.permissions);
      },
    });

    const [isResetPassword, setIsResetPassword] = useState(false);
    const isLoginDisabled = useMemo(() => !values.email || !values.password, [
      values,
    ]);

    const handleClickResetPassword = useCallback(() => {
      paths.resetPasswordPath().push();
    }, []);

    useHandleSubmit(async (values: { email: string; password: string }) => {
      await backofficeSignIn({
        variables: {
          email: values.email.trim(),
          password: values.password,
        },
      });
    }, []);

    const handleClickGoBack = useCallback(() => {
      setIsResetPassword(false);
    }, []);

    const activeAccount = useMemo(() => instance.getActiveAccount(), [
      instance,
    ]);

    const handleClickLoginWithAzureAd = useCallback(async () => {
      await instance.loginRedirect();
    }, [instance]);

    const handleLogoutAzureAd = useCallback(async () => {
      await instance.logout({
        postLogoutRedirectUri: "/",
        account: activeAccount,
        onRedirectNavigate: (url) => {
          instance.setActiveAccount(null);
          return true;
        },
      });
    }, [activeAccount, instance]);

    const isShowLogout = useMemo(() => {
      const account = accounts[0];
      return !currentUser && account;
    }, [accounts, currentUser]);

    return {
      currentEmail: values.email.trim(),
      handleClickGoBack,
      isLoginDisabled,
      handleClickLoginWithAzureAd,
      handleLogoutAzureAd,
      isShowLogout,
      handleClickResetPassword,
      isResetPassword,
    };
  })
);

export const LoginPage = enhancer(LoginPageComponent);
