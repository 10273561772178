import { Box } from "components";
import {
  compose,
  withAuthorize,
  withFormik,
  withHooks,
  withTranslation,
} from "enhancers";
import { BreadcrumbsProps } from "layouts/PageContent";
import Iframe from "react-iframe";
import styled from "styled-components";
import { gql, homePath } from "utils/helper";
const FilterCountNotify = styled(Box)`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #ffa500;
  display: flex;
`;
const RequestDetailPage = (props: any) => (
  <>
    <Iframe
      url={props.url}
      title="Detail View"
      width="100%"
      height="100%"
      allow="fullscreen"
    />
  </>
);

export const API = {
  GET_URL_PREVIEW: gql`
    query GET_URL_PREVIEW($claimRequestId: String!) {
      generatePreviewAppUrl(claimRequestId: $claimRequestId)
    }
  `,
};

const enhancer = compose(
  withAuthorize(),
  withFormik({
    mapPropsToValues: () => ({}),
  }),
  withTranslation({
    prefix: "pages.main.request.detail",
  }),
  withHooks((props: any, hooks: any) => {
    const { t } = props;

    const { useMemo, useQuery, useParams } = hooks;
    const { id } = useParams();
    const { data } = useQuery(API.GET_URL_PREVIEW, {
      variables: { claimRequestId: id },
    });
    const url = data?.generatePreviewAppUrl.toString();
    console.log(url);
    const breadcrumbs = useMemo(
      (): BreadcrumbsProps[] => [
        { label: t(".home"), path: homePath() },
        { label: t(".title"), path: null },
      ],
      [t]
    );

    return {
      breadcrumbs,
      url,
    };
  })
);

export default enhancer(RequestDetailPage);
