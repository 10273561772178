import { useMutation } from "@apollo/client";
import { Notification } from "components";
import { get, isEmpty, set } from "lodash";

const useCustomMutation = (api, props, mutationProps) => {
  const { setErrors, t } = props;
  const processFieldError = (fieldError, field, message) => {
    if (field) {
      set(fieldError, field, message);
    } else {
      return `${message}\n`;
    }
    return "";
  };
  const processErrors = (originalError, fieldError) => {
    let errorMessages = "";
    for (const { field, message } of originalError) {
      errorMessages += processFieldError(fieldError, field, message);
    }
    return errorMessages;
  };
  return useMutation(api, {
    ...mutationProps,
    onError: (error) => {
      if (!mutationProps?.skipSetError) {
        if (
          error?.graphQLErrors[0]?.extensions.extensions.http.status === 422 &&
          setErrors
        ) {
          const errors = get(error, "graphQLErrors[0].extensions");
          const fieldError = {};
          let __error__ = "";

          const originalError = get(errors, "extensions.originalError", null);

          if (originalError instanceof Array) {
            __error__ += processErrors(originalError, fieldError);
          }
          setErrors({ ...fieldError, __error__ });
          if (isEmpty(fieldError))
            Notification.error(
              t(
                get(
                  error,
                  "graphQLErrors[0].extensions.extensions.originalError[0].message",
                  "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน"
                )
              )
            );
        } else if (
          error?.graphQLErrors[0]?.extensions.extensions.http.status === 422
        ) {
          Notification.error(
            t(get(error, "message", "เกิดข้อผิดพลาดบางอย่าง โปรดติดต่อทีมงาน"))
          );
        }
      }

      if (mutationProps?.onError) {
        mutationProps.onError(error);
      } else {
        throw new Error(error.toString());
      }
    },
  });
};

export default useCustomMutation;
