import { compose, defaultProps, withHooks } from "enhancers";
import { Box, Field, Select, T, Typography } from "components";
import { format, getMonth, lastDayOfMonth, getDay, getDate } from "date-fns";
import { map, range } from "lodash";
import { AppColor } from "theme/app-color";

const DateMonthTimePicker = (props: any) => (
  <Box position="relative" display="flex" flexDirection="column">
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box width="100px" mr={6}>
        <Field
          component={Select}
          name={`${props.name}.date`}
          label="วันที่"
          required
          fullWidth
          options={props.dateOptions}
          disabled={props.disabled}
          error={props.isError}
        />
      </Box>
      <Box width="200px">
        <Field
          component={Select}
          name={`${props.name}.month`}
          label="เดือน"
          required
          fullWidth
          options={props.monthOptions}
          disabled={props.disabled}
          error={props.isError}
        />
      </Box>
      <Box>
        <Typography variant="body1" mr={2} ml={2}>
          {"เวลา"}
        </Typography>
      </Box>
      <Box width="100px">
        <Field
          component={Select}
          name={`${props.name}.hour`}
          fullWidth
          options={props.hourOptions}
          disabled={props.disabled}
          error={props.isError}
        />
      </Box>

      <Typography variant="body1" mr={2} ml={2}>
        {":"}
      </Typography>
      <Box width="100px">
        <Field
          component={Select}
          name={`${props.name}.minute`}
          fullWidth
          options={props.minuteOptions}
          disabled={props.disabled}
          error={props.isError}
        />
      </Box>
    </Box>

    {props.errorMessage && (
      <Typography
        variant="caption"
        color={AppColor["Other/Danger"]}
        style={{ marginTop: "6px" }}
      >
        {`${props.errorMessage}`}
      </Typography>
    )}
  </Box>
);

const enhancer = compose(
  defaultProps({ format: "dd/MM/yyyy" }),
  withHooks((props: any, hooks: any) => {
    const { useMemo, useFormikContext, useEffect } = hooks;
    const { field, errorMessage = "", disabled = false } = props;
    const formikBag = useFormikContext();
    const { date, month, hour = 0, minute = 0 } = field.value || {};
    const selectedMonth = useMemo(() => {
      const currentDate = new Date();
      const result = new Date(
        currentDate.getFullYear(),
        month - 1,
        currentDate.getDay()
      );
      return getMonth(result);
    }, [month]);

    const currentDate = new Date();
    const resultDate = new Date(
      currentDate.getFullYear(),
      selectedMonth,
      currentDate.getDay()
    );
    const selectedLastDayOfMonth = lastDayOfMonth(resultDate);
    const lastDay = getDate(selectedLastDayOfMonth);

    const dateOptions = useMemo(() => {
      const options = [];
      if (date && lastDay && date > lastDay) {
        for (let i = 1; i <= lastDay; i++) {
          options.push({ label: String(i), value: i });
        }
        if (date && lastDay && date > lastDay) {
          formikBag.setFieldValue(`${field.name}.date`, null);
        }

        return options;
      }
      for (let i = 1; i <= 31; i++) {
        options.push({ label: String(i), value: i });
      }
      return options;
    }, [lastDay]);

    const monthOptions = useMemo(() => {
      return map(range(1, 12 + 1), (i) => ({
        label: [
          "มกราคม",
          "กุมภาพันธ์",
          "มีนาคม",
          "เมษายน",
          "พฤษภาคม",
          "มิถุนายน",
          "กรกฎาคม",
          "สิงหาคม",
          "กันยายน",
          "ตุลาคม",
          "พฤศจิกายน",
          "ธันวาคม",
        ][i - 1],
        value: i,
      }));
    }, []);

    const hourOptions = useMemo(() => {
      return Array.from({ length: 24 }, (_, i) => ({
        label: i.toString().padStart(2, "0"),
        value: i,
      }));
    }, []);

    const minuteOptions = useMemo(() => {
      return Array.from({ length: 60 }, (_, i) => ({
        label: i.toString().padStart(2, "0"),
        value: i,
      }));
    }, []);

    useEffect(() => {
      formikBag.setFieldValue(`${field.name}.hour`, 0);
      formikBag.setFieldValue(`${field.name}.minute`, 0);
    }, []);

    useEffect(() => {
      if (disabled) {
        formikBag.setFieldValue(`${field.name}.date`, undefined);
        formikBag.setFieldValue(`${field.name}.month`, undefined);
        formikBag.setFieldValue(`${field.name}.hour`, 0);
        formikBag.setFieldValue(`${field.name}.minute`, 0);
      }
    }, [disabled]);

    useEffect(() => {
      if (errorMessage) {
        formikBag.setFieldError(field.name, {
          date: "",
          month: "",
          hour: "",
          minute: "",
        });
      } else {
        formikBag.setFieldError(field.name, {
          date: null,
          month: null,
          hour: null,
          minute: null,
        });
      }
    }, [errorMessage, field.value]);

    const isError = useMemo(() => {
      return errorMessage ? true : false;
    }, [errorMessage]);
    return {
      dateOptions,
      monthOptions,
      hourOptions,
      minuteOptions,
      name: field.name,
      errorMessage,
      disabled,
      isError,
    };
  })
);

export default enhancer(DateMonthTimePicker);
