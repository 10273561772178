import { FormControl } from "@material-ui/core";
import { CheckboxWithLabel } from "formik-material-ui";
import Field from "./Field";
import Typography from "./Typography";
import { compose, withHooks, withTranslation } from "enhancers";
import { Box } from ".";
import { AppColor } from "theme/app-color";

const Checkboxes = (props: any) => (
  <FormControl component="fieldset" style={{ display: "flex" }}>
    <Typography variant="h4">{props.t(".scope")}</Typography>
    <Box padding="24px 24px 16px 24px">
      {Object.keys(props.options).map((key) => (
        <>
          {key !== ".syncing" && (
            <Typography variant="h6">{props.t(key)}</Typography>
          )}
          <Box display="flex" mb={6}>
            {props.options[key].map((item: any, index: number) => (
              <Box key={"options_" + props.name} minWidth="150px" mr={6}>
                <Field
                  type="checkbox"
                  component={CheckboxWithLabel}
                  name={props.name}
                  key={item.value}
                  value={item.value}
                  Label={{ label: props.t(item.label) }}
                  fast={false}
                  disabled={
                    !props.hasEditPermission ||
                    props.isSuperAdminRole ||
                    props.disabledList.includes(item.value)
                  }
                />
              </Box>
            ))}
          </Box>
        </>
      ))}
    </Box>
    <Typography variant="h4">{props.t(".reportScope")}</Typography>
    <Box padding="24px 24px 0px 24px">
      {Object.keys(props.exportReportPermissionOptions).map((key) => (
        <>
          {key !== ".export" && (
            <Typography
              variant="body1"
              color={AppColor["Text Light/Secondary"]}
            >
              {props.t(key)}
            </Typography>
          )}
          <Box display="flex" mt={2} mb={6} flexDirection="column">
            {props.exportReportPermissionOptions[key].map(
              (item: any, index: number) => (
                <Box
                  key={"exportReportPermissionOptions_" + props.name}
                  minWidth="150px"
                  mr={6}
                >
                  <Field
                    type="checkbox"
                    component={CheckboxWithLabel}
                    name={props.name}
                    key={item.value}
                    value={item.value}
                    Label={{ label: props.t(item.label) }}
                    fast={false}
                    disabled={
                      !props.hasEditPermission || props.isSuperAdminRole
                    }
                  />
                </Box>
              )
            )}
          </Box>
        </>
      ))}
    </Box>
  </FormControl>
);

const enhancer = compose(
  withTranslation({ prefix: "pages.main.roles.new" }),
  withHooks((props: any, hooks: any) => {
    return { ...props };
  })
);

export default enhancer(Checkboxes);
