import {
  compose,
  withFormik,
  withHooks,
  withTranslation,
  withStores,
} from "enhancers";
import { PageContent } from "layouts";
import {
  gql,
  paths,
  getErrorMessage,
  notifyError,
  homePath,
  Yup,
} from "utils/helper";
import {
  Box,
  Form,
  Field,
  TextField,
  Button,
  Notification,
  FieldArray,
  Typography,
  Grid,
  UploadFile,
  Modal,
  DateMonthTimePicker,
  Checkbox,
  Divider,
} from "components";

import { makeStyles } from "@material-ui/core";

import "react-js-cron/dist/styles.css";
import { AppColor } from "theme/app-color";
import { isEmpty, isEqual, omit } from "lodash";
import withPreventLeaveDirtyForm from "enhancers/withPreventLeaveDirtyForm";
import { EnumSettingState } from "constants/enums/setting";
import LoadingModal from "components/advance/LoadingModal";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: AppColor["Other/Info"],
    borderColor: AppColor["Other/Info"],
    color: "white",
    "&:hover": {
      backgroundColor: "#2296F3",
      borderColor: "#2296F3",
      color: "white",
    },
  },
}));

const Settings = (props: any) => (
  <>
    <LoadingModal isOpen={props.imageLoading} title="กำลังบีบอัดไฟล์" />
    <PageContent title={props.title} breadcrumbs={props.breadcrumbs}>
      <Form>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="h4">{props.t(".title")}</Typography>
          <Box mt={2}>
            <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
              {props.t(".titleDetail")}
            </Typography>
          </Box>
          <Grid container spacing={4} mt={4} alignItems="center">
            <Grid item xs={12} sm={5}>
              <Typography variant="body1">{props.t(".pageDetail")}</Typography>
            </Grid>
            <Grid
              item
              xs={7}
              sm={7}
              container
              flexDirection="row"
              justify="flex-end"
            >
              <Field
                component={Checkbox}
                name="isShowSuccesRequest"
                type="checkbox"
                label={props.t(".isShowSuccesRequest")}
                fullWidth
                disabled={!props.hasEditPermission}
              />
              <Field
                component={Checkbox}
                name="isShowHistoryPage"
                type="checkbox"
                label={props.t(".isShowHistoryPage")}
                fullWidth
                disabled={!props.hasEditPermission}
                style={{ marginLeft: "20px" }}
              />
              <Field
                component={Checkbox}
                name="isShowLandingPage"
                type="checkbox"
                label={props.t(".isShowLandingPage")}
                fullWidth
                disabled={!props.hasEditPermission}
                style={{ marginLeft: "20px" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1">{props.t(".imageDetail")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} container justify="flex-end">
              <Field
                component={UploadFile}
                name="images"
                style={{ marginRight: "16px" }}
                onLoading={(loading: any) => props.setImageLoading(loading)}
              />
            </Grid>
          </Grid>
          <Divider my={6} />
          <Box mt={4}>
            <Typography variant="h4">{props.t(".syncSetting")}</Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="body1" color={AppColor["Text/Dark Grey"]}>
              {props.t(".syncSettingDetail")}
            </Typography>
          </Box>
          <Grid container spacing={4} mt={4} alignItems="center">
            <Grid item xs={12} sm={5}>
              <Typography variant="body1">
                {props.t(".syncOpenData")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Field
                component={DateMonthTimePicker}
                name="startClaimRequestEnrollment"
                fullWidth
                disabled={!props.hasEditPermission}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="body1">
                {props.t(".syncCloseData")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Field
                component={DateMonthTimePicker}
                name="endClaimRequestEnrollment"
                fullWidth
                disabled={!props.hasEditPermission}
                errorMessage={props.errorEndClaim}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="body1">
                {props.t(".notifyEmployee")}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={7}>
              <Field
                component={Checkbox}
                name="isNotifyEmployee"
                type="checkbox"
                label={props.t(".isNotifyEmployee")}
                fullWidth
                disabled={!props.hasEditPermission}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="body1">
                {props.t(".startDateNotify")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Field
                component={DateMonthTimePicker}
                name="notifyBeforeStartAt"
                fullWidth
                disabled={props.disabledNotify}
                errorMessage={props.errorNotifyBefore}
              />
            </Grid>
          </Grid>
          <Divider my={6} />
        </Box>
        {props.hasEditPermission && (
          <Box mt={4}>
            <Button
              width={74}
              mr={4}
              onClick={props.handleClickCancel}
              variant="outlined"
            >
              {props.t(".cancel")}
            </Button>
            <Button
              onClick={props.onSubmit}
              width={74}
              disabled={!props.isValid}
              style={{ border: "none" }}
              variant="contained"
            >
              {props.t(".save")}
            </Button>
          </Box>
        )}
      </Form>
    </PageContent>
  </>
);

export const API = {
  FETCH_SETTING: gql`
    query FETCH_SETTING {
      setting {
        id
        showDetailOn
        startClaimRequestEnrollment
        endClaimRequestEnrollment
        notifyBeforeStartAt
        images {
          url
          filename
        }
      }
    }
  `,
  UPDATE_SETTING: gql`
    mutation UPDATE_SETTING(
      $showDetailOn: [String!]
      $startClaimRequestEnrollment: DateTime!
      $endClaimRequestEnrollment: DateTime!
      $notifyBeforeStartAt: DateTime
      $images: [Upload!]
    ) {
      createSetting(
        input: {
          showDetailOn: $showDetailOn
          startClaimRequestEnrollment: $startClaimRequestEnrollment
          endClaimRequestEnrollment: $endClaimRequestEnrollment
          notifyBeforeStartAt: $notifyBeforeStartAt
          images: $images
        }
      ) {
        showDetailOn
        startClaimRequestEnrollment
        endClaimRequestEnrollment
        notifyBeforeStartAt
        images {
          url
          filename
        }
      }
    }
  `,
};

const validationSchema = Yup.object().shape({
  startClaimRequestEnrollment: Yup.object()
    .shape({
      date: Yup.number().required(),
      month: Yup.number().required(),
    })
    .required(),
  endClaimRequestEnrollment: Yup.object()
    .shape({
      date: Yup.number().required(),
      month: Yup.number().required(),
    })
    .required(),
  notifyBeforeStartAt: Yup.object()
    .when("isNotifyEmployee", {
      is: true,
      then: Yup.object().shape({
        date: Yup.number().required(),
        month: Yup.number().required(),
      }),
    })
    .required(),
});

const enhancer = compose(
  withFormik({}),
  withPreventLeaveDirtyForm(),
  withStores((stores: any) => ({
    currentUser: stores.appStore.currentUser,
  })),
  withTranslation({ prefix: "pages.main.settings.index" }),
  withHooks((props: any, hooks: any) => {
    const {
      useQuery,
      useState,
      useMemo,
      useMutation,
      useEffect,
      useHandleSubmit,
      useCallback,
      useLazyQuery,
    } = hooks;
    const {
      currentUser,
      initialValues,
      setInitialValues,
      setValues,
      dirty,
      t,
      values,
      setFieldTouched,
    } = props;
    const classes = useStyles();

    const [setting, setSetting] = useState();
    const [isFormValid, setIsFormValid] = useState();
    const [imageLoading, setImageLoading] = useState(false);
    const [createSetting] = useMutation(API.UPDATE_SETTING, {
      onCompleted: async (data: any) => {
        Notification.success({
          message: t(".saveSuccess"),
          style: { backgroundColor: AppColor["Other/Success"] },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        setSetting(data.createSetting);
        const settingData = await mapSettingData(data.createSetting);
        setInitialValues({ ...settingData });
      },
      onError: (error: any) => {
        Notification.error({
          message: `${error}`,
          style: { backgroundColor: AppColor["Other/Danger"] },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        paths.adminsPath().push();
      },
    });

    const [fetchSetting] = useLazyQuery(API.FETCH_SETTING, {
      fetchPolicy: "network-only",
      onCompleted: async (data: any) => {
        setSetting(data.setting);
        const settingData = await mapSettingData(data.setting);
        setInitialValues({ ...settingData });
      },
      onError: (error: any) => {
        Notification.error({
          message: `${error}`,
          style: { backgroundColor: AppColor["Other/Danger"] },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        paths.adminsPath().push();
      },
    });

    const hasEditPermission = ["SETTING_MANAGEMENT_EDIT"].every((perm) =>
      currentUser?.role?.permissions.includes(perm)
    );

    const title = t(".settingMenu");
    const breadcrumbs = useMemo(() => {
      return [
        { path: homePath(), label: t(".home") },
        { path: null, label: title },
      ];
    }, [title, t]);

    const handleClickCancel = useCallback(() => {
      Modal.open({
        title: t(".cancelEdit"),
        children: (
          <Box flex={1} mb={5} display="flex" flexDirection="row">
            <Typography variant="body1" color="Text/Dark Grey">
              {t(".cancelConfirm")}
            </Typography>
          </Box>
        ),
        cancelButtonLabel: t(".close"),
        okButtonLabel: t(".canceledEdit"),
        onOk: async ({ close }: any) => {
          close();
          setValues(initialValues);
        },
      });
    }, [t, dirty, setValues, initialValues]);

    const errorStartClaim = useMemo(() => {
      return validateStartClaimRequestEnrollment(values);
    }, [
      values,
      values.startClaimRequestEnrollment,
      values.endClaimRequestEnrollment,
      values.startClaimRequestEnrollment?.date,
      values.endClaimRequestEnrollment?.date,
      values.startClaimRequestEnrollment?.month,
      values.endClaimRequestEnrollment?.month,
      values.notifyBeforeStartAt,
    ]);

    const errorEndClaim = useMemo(() => {
      const msg = validateEndClaimRequestEnrollment(values);
      if (msg) {
        setFieldTouched("endClaimRequestEnrollment.date", true);
      } else {
        setFieldTouched("endClaimRequestEnrollment.date", false);
      }
      return msg;
    }, [
      values,
      values.endClaimRequestEnrollment,
      values.endClaimRequestEnrollment?.date,
      values.endClaimRequestEnrollment?.month,
      values.startClaimRequestEnrollment,
      values.startClaimRequestEnrollment?.date,
      values.startClaimRequestEnrollment?.month,
      errorStartClaim,
    ]);

    const errorNotifyBefore = useMemo(() => {
      const msg = validateNotifyBeforeStartAt(values);
      if (msg) {
        setFieldTouched("notifyBeforeStartAt.date", true);
      } else {
        setFieldTouched("notifyBeforeStartAt.date", false);
      }
      return msg;
    }, [
      values,
      values.startClaimRequestEnrollment,
      values.endClaimRequestEnrollment,
      values.startClaimRequestEnrollment?.date,
      values.endClaimRequestEnrollment?.date,
      values.startClaimRequestEnrollment?.month,
      values.endClaimRequestEnrollment?.month,
      values.notifyBeforeStartAt,
      values.notifyBeforeStartAt?.date,
      values.notifyBeforeStartAt?.month,
      errorStartClaim,
      errorEndClaim,
    ]);

    const onSubmit = useCallback(async () => {
      try {
        validationSchema.validateSync(values);

        const result = {
          showDetailOn: mapShowDetail(values),
          startClaimRequestEnrollment: formatDateTime(
            values.startClaimRequestEnrollment
          ),
          endClaimRequestEnrollment: formatDateTime(
            values.endClaimRequestEnrollment
          ),
          notifyBeforeStartAt: values.isNotifyEmployee
            ? formatDateTime(values.notifyBeforeStartAt)
            : null,
          images: values.images,
        };

        await createSetting({
          variables: result,
        });
      } catch (e) {
        Notification.error({
          message: e,
          style: { backgroundColor: AppColor["Other/Danger"] },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    }, [values, setting]);

    useEffect(() => {
      fetchSetting();
    }, [fetchSetting, dirty]);

    useEffect(() => {
      try {
        validationSchema.validateSync(values);
        if (
          validateStartClaimRequestEnrollment(values) ||
          validateEndClaimRequestEnrollment(values) ||
          validateNotifyBeforeStartAt(values)
        ) {
          throw new Error("Invalid Range");
        }
        setIsFormValid(true);
      } catch (error) {
        setIsFormValid(false);
      }
    }, [values]);

    console.log("v", values);

    return {
      title,
      breadcrumbs,
      classes,
      hasEditPermission,
      handleClickCancel,
      dirty,
      disabledNotify: !values.isNotifyEmployee,
      errorStartClaim,
      errorEndClaim,
      errorNotifyBefore,
      isValid: isFormValid,
      onSubmit,
      setImageLoading,
      imageLoading,
    };
  })
);

const convertImagesToFilesObject = async (images: any[]) => {
  const result = await Promise.all(
    images.map(async (img: any) => {
      if (img.filename && img.url) {
        const blob = await fetch(img.url).then((r) => r.blob());
        const file = new File([blob], img.filename, {
          type: blob.type,
        });
        return file;
      } else {
        return img;
      }
    })
  );
  if (result.length === 0) {
    return null;
  }
  return result[0];
};

const mapSettingData = async (setting: any) => {
  const {
    showDetailOn,
    startClaimRequestEnrollment,
    endClaimRequestEnrollment,
    notifyBeforeStartAt,
    images,
  } = setting;
  const processImages = await convertImagesToFilesObject(images);
  return {
    ...mapInitShowDetail(showDetailOn),
    isNotifyEmployee: notifyBeforeStartAt ? true : false,
    startClaimRequestEnrollment: convertTimeStamp(startClaimRequestEnrollment),
    endClaimRequestEnrollment: convertTimeStamp(endClaimRequestEnrollment),
    notifyBeforeStartAt: convertTimeStamp(notifyBeforeStartAt),
    images: processImages,
  };
};

const convertTimeStamp = (value: any) => {
  if (value) {
    const dateObject = new Date(value);
    const date = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const hour = dateObject.getHours();
    const minute = dateObject.getMinutes();
    return { date, month, hour, minute };
  }
  return { hour: 0, minute: 0 };
};

const formatDateTime = (result: any) => {
  const { date, month, hour = 0, minute = 0 } = result;
  const currentDate = new Date();
  const selectedDate = new Date(
    currentDate.getFullYear(),
    month - 1,
    date,
    hour,
    minute
  );
  return selectedDate;
};

const validateStartClaimRequestEnrollment = (value: any) => {
  let message = "";
  const startDate = value.startClaimRequestEnrollment;
  const endDate = value.endClaimRequestEnrollment;
  if (endDate && startDate) {
    const convertStartDate = formatDateTime(startDate);
    const convertEndDate = formatDateTime(endDate);
    message =
      convertStartDate >= convertEndDate
        ? "วันที่เปิดรอบการเบิกจะต้องไม่เกินวันที่ปิดรอบการเบิก"
        : "";
  }
  return message;
};

const validateEndClaimRequestEnrollment = (value: any) => {
  let message = "";
  const startDate = value.startClaimRequestEnrollment;
  const endDate = value.endClaimRequestEnrollment;
  if (endDate && startDate) {
    const convertStartDate = formatDateTime(startDate);
    const convertEndDate = formatDateTime(endDate);
    message =
      convertStartDate >= convertEndDate
        ? "วันที่ปิดรอบการเบิกจะต้องมากกว่าวันที่เปิดรอบการเบิก"
        : "";
  }
  return message;
};

const validateNotifyBeforeStartAt = (value: any) => {
  let message = "";
  const startDate = value.startClaimRequestEnrollment;
  const endDate = value.endClaimRequestEnrollment;
  const notifyDate = value.notifyBeforeStartAt;
  if (endDate && startDate && notifyDate && value.isNotifyEmployee) {
    const convertStartDate = formatDateTime(startDate);
    const convertEndDate = formatDateTime(endDate);
    const convertNotifyDate = formatDateTime(notifyDate);
    message =
      convertNotifyDate <= convertStartDate ||
      convertNotifyDate >= convertEndDate
        ? "วันที่เริ่มแจ้งเตือนจะต้องอยู่ในช่วงของรอบการเบิก"
        : "";
  }
  return message;
};

const mapShowDetail = (values: any) => {
  let result = [];
  if (values.isShowSuccesRequest) {
    result.push(EnumSettingState.ON_REQUEST_SUCCESS);
  }

  if (values.isShowHistoryPage) {
    result.push(EnumSettingState.ON_HISTORY_PAGE);
  }

  if (values.isShowLandingPage) {
    result.push(EnumSettingState.IN_FIRST_PAGE);
  }

  return result;
};

const mapInitShowDetail = (showDetailSetting: any) => {
  let result = {
    isShowSuccesRequest: false,
    isShowHistoryPage: false,
    isShowLandingPage: false,
  };
  if (showDetailSetting?.includes(EnumSettingState.ON_REQUEST_SUCCESS)) {
    result.isShowSuccesRequest = true;
  }

  if (showDetailSetting?.includes(EnumSettingState.ON_HISTORY_PAGE)) {
    result.isShowHistoryPage = true;
  }

  if (showDetailSetting?.includes(EnumSettingState.IN_FIRST_PAGE)) {
    result.isShowLandingPage = true;
  }

  return result;
};

export default enhancer(Settings);
